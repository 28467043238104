.p-editOrder {
    &_textWrapper {
        @include adjust-flex(flex-start);

        span {
            flex-basis: 50%;
            @include font-base(14,18);

            &:first-child {
                font-weight: 700;
            }
        }
    }

    &_paymentDetail {
        @include adjust-flex(flex-start, flex-start);

        &_left,
        &_right {
            flex-basis: 50%;
        }
    }
}
