.p-orderManagement {
    table {
        width: auto !important;

        th span,
        td > span {
            display: inline-block;
            width: max-content;
        }
    }
}
