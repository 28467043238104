.p-aiServerManagement {
    $root: &;

    .ant-table-row {
        &:hover {
            #{$root}_overlay-hidden {
                background-color: #fafafa;
            }
        }
    }

    &_apiKey {
        position: relative;
    }

    &_overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        // backdrop-filter: blur(0);
        transition: background-color $transition ease-in-out;

        &-hidden {
            // backdrop-filter: blur(0.3125rem);
            background-color: $white;
        }
    }
}

.p-statusLabelAIServer {
    $root: &;
    display: inline-block;

    &_wrapper {
        display: inline-block;
        padding: 0 rem(14);
    }

    &_dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 6px;
        flex-shrink: 0;
        position: relative;

        &-isStopping,
        &-isOffline,
        &-notAvailable {
            background-color: $red;
        }

        &-stopped {
            background-color: $sandstorm;
        }

        &-isUsing,
        &-isOnline,
        &-available {
            background-color: $green;

            &::after {
                position: absolute;
                content: '';
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: $green;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                animation: fadeInOutAnimation 1s infinite;
                animation-direction: alternate-reverse;
                animation-timing-function: ease-in-out;
            }

        }
    }
}

@keyframes fadeInOutAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
